import {
  emptyInvestmentSharesAndEquityPublicCompanyItem,
  Nullable,
  PortfolioCompanyClientOrCustomerAdvisoryOrConsultingItem,
  PortfolioCompanyClientOrCustomerCashManagementOrDepositsItem,
  PortfolioCompanyClientOrCustomerForeignExchangeItem,
  PortfolioCompanyClientOrCustomerHedgingOrRiskManagementItem,
  PortfolioCompanyClientOrCustomerItem,
  PortfolioCompanyClientOrCustomerLoanOrDebtRelatedItem,
  PortfolioCompanyClientOrCustomerOtherItem,
  PortfolioCompanyEmployerItem,
  PortfolioCompanyInvestmentBondsOrDebtItem,
  PortfolioCompanyInvestmentOtherItem,
  PortfolioCompanyInvestmentSharesOrEquityItem,
  PortfolioCompanyInvestmentType,
  PortfolioCompanyOtherItem,
  PortfolioCompanyPartnerItem,
  PortfolioCompanyProductType,
  PortfolioCompanyRelationshipType,
  PortfolioCompanySupplierItem,
  PortfolioItemType,
} from 'types/portfolioItem';
import { PortfolioItemPublicCompanySearchResult } from 'views/Portfolios/PortfoliosModal/api';

export const createCompanyPartnerItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult,
): Nullable<PortfolioCompanyPartnerItem> => ({
  id: null,
  companyId: searchResult.id,
  name: searchResult.name,
  type: PortfolioItemType.publicCompany,
  relationshipType: PortfolioCompanyRelationshipType.partner,
  primaryCurrency: searchResult.data.currency,
  annualMonetaryIncomeFromPartner: null,
  majorityOrMinority: null,
});

export const createCompanyClientOrCustomerItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult,
): Nullable<PortfolioCompanyClientOrCustomerItem> => ({
  id: null,
  companyId: searchResult.id,
  name: searchResult.name,
  type: PortfolioItemType.publicCompany,
  relationshipType: PortfolioCompanyRelationshipType.clientOrCustomer,
  productType: null,
  currency: null,
  productTypeDescription: null,
  annualMonetaryIncomeFromClient: null,
});

export const createCompanyInvestmentSharesOrEquityItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult,
): Nullable<PortfolioCompanyInvestmentSharesOrEquityItem> => ({
  ...emptyInvestmentSharesAndEquityPublicCompanyItem,

  name: searchResult.name,
  companyId: searchResult.id,
  currency: searchResult.data.currency,
  averagePricePerShare: searchResult.data.price,
});

export const createCompanyInvestmentOtherItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult,
): Nullable<PortfolioCompanyInvestmentOtherItem> => ({
  id: null,
  name: searchResult.name,
  companyId: searchResult.id,
  type: PortfolioItemType.publicCompany,
  relationshipType: PortfolioCompanyRelationshipType.investment,
  investmentType: PortfolioCompanyInvestmentType.other,
  investmentTypeDescription: null,
  purchaseTimestamp: null,
  expectedAnnualReturnPercent: null,
  expectedHoldingPeriodMonths: null,
});

export const createCompanyInvestmentBondsOrDebtItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult,
): Nullable<PortfolioCompanyInvestmentBondsOrDebtItem> => ({
  ...emptyInvestmentSharesAndEquityPublicCompanyItem,

  name: searchResult.name,
  companyId: searchResult.id,
  investmentType: PortfolioCompanyInvestmentType.bondsOrDebt,

  purchaseTimestamp: null,
  maturityTimestamp: null,
  annualCouponRateOrInterestRateCharged: null,
  bondOrDebtPrincipalAmount: null,
  averagePurchasePriceOfBond: null,
});

export const createCompanySupplierItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult,
): Nullable<PortfolioCompanySupplierItem> => ({
  id: null,
  companyId: searchResult.id,
  name: searchResult.name,
  type: PortfolioItemType.publicCompany,
  relationshipType: PortfolioCompanyRelationshipType.supplier,
  productsOrServicesSupplied: null,
  currency: 'USD',
  annualAmountPaidToSupplier: null,
});

export const createCompanyEmployerItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult,
): Nullable<PortfolioCompanyEmployerItem> => ({
  id: null,
  companyId: searchResult.id,
  name: searchResult.name,
  type: PortfolioItemType.publicCompany,
  relationshipType: PortfolioCompanyRelationshipType.employer,
  receiveStockOptions: null,
});

export const createCompanyOtherItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult,
): Nullable<PortfolioCompanyOtherItem> => ({
  id: null,
  companyId: searchResult.id,
  name: searchResult.name,
  type: PortfolioItemType.publicCompany,
  relationshipType: PortfolioCompanyRelationshipType.other,
  description: null,
});

export const createCompanyClientOrCustomerAdvisoryOrConsultingItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult,
): Nullable<PortfolioCompanyClientOrCustomerAdvisoryOrConsultingItem> => ({
  id: null,
  companyId: searchResult.id,
  name: searchResult.name,
  type: PortfolioItemType.publicCompany,
  relationshipType: PortfolioCompanyRelationshipType.clientOrCustomer,
  productType: PortfolioCompanyProductType.advisoryOrConsulting,
  advisoryOrConsultingType: null,
  currency: 'USD',
  annualMonetaryIncomeFromClient: null,
});

export const createCompanyClientOrCustomerCashManagementOrDepositItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult,
): Nullable<PortfolioCompanyClientOrCustomerCashManagementOrDepositsItem> => ({
  id: null,
  companyId: searchResult.id,
  name: searchResult.name,
  type: PortfolioItemType.publicCompany,
  relationshipType: PortfolioCompanyRelationshipType.clientOrCustomer,
  productType: PortfolioCompanyProductType.cashManagementOrDeposits,
  currency: 'USD',
  annualMonetaryIncomeFromClient: null,
});

export const createCompanyClientOrCustomerForeignExchangeItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult,
): Nullable<PortfolioCompanyClientOrCustomerForeignExchangeItem> => ({
  id: null,
  companyId: searchResult.id,
  name: searchResult.name,
  type: PortfolioItemType.publicCompany,
  relationshipType: PortfolioCompanyRelationshipType.clientOrCustomer,
  productType: PortfolioCompanyProductType.foreignExchange,
  currenciesOfInterest: [],
  currency: 'USD',
  annualMonetaryIncomeFromClient: null,
});

export const createCompanyClientOrCustomerHedgingOrRiskManagementItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult,
): Nullable<PortfolioCompanyClientOrCustomerHedgingOrRiskManagementItem> => ({
  id: null,
  companyId: searchResult.id,
  name: searchResult.name,
  type: PortfolioItemType.publicCompany,
  relationshipType: PortfolioCompanyRelationshipType.clientOrCustomer,
  productType: PortfolioCompanyProductType.hedgingOrRiskManagement,
  subjects: [],
  currency: 'USD',
  annualMonetaryIncomeFromClient: null,
});

export const createCompanyClientOrCustomerLoanOrDebtRelatedItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult,
): Nullable<PortfolioCompanyClientOrCustomerLoanOrDebtRelatedItem> => ({
  id: null,
  companyId: searchResult.id,
  name: searchResult.name,
  type: PortfolioItemType.publicCompany,
  relationshipType: PortfolioCompanyRelationshipType.clientOrCustomer,
  productType: PortfolioCompanyProductType.loansOrDebtRelated,
  insuranceTimestamp: Date.now(),
  maturityTimestamp: null,
  principalAmount: null,
  currency: 'USD',
  annualInterestRateCharged: null,
});

export const createCompanyClientOrCustomerOtherItemFromSearchResult = (
  searchResult: PortfolioItemPublicCompanySearchResult,
): Nullable<PortfolioCompanyClientOrCustomerOtherItem> => ({
  id: null,
  companyId: searchResult.id,
  name: searchResult.name,
  type: PortfolioItemType.publicCompany,
  relationshipType: PortfolioCompanyRelationshipType.clientOrCustomer,
  productType: PortfolioCompanyProductType.other,
  productTypeDescription: null,
  currency: 'USD',
  annualMonetaryIncomeFromClient: null,
});
