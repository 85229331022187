import { SearchBox } from 'components/searchBox';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { defaultOptions } from 'utils/popper';
import {
  PortfolioItemSearchResult,
  useLazySearchQuery,
} from 'views/Portfolios/PortfoliosModal/api';
import { SearchResultsDropdown } from 'views/Portfolios/PortfoliosModal/searchResultDropdown';

interface Props {
  onSelected?(item: PortfolioItemSearchResult): void;
}

export const AssetSearchInput: React.FC<Props> = ({ onSelected }: Props): React.ReactElement => {
  const [currentValue, setCurrentValue] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
  const [popup, setPopup] = useState<HTMLDivElement | null>(null);
  const [items, setItems] = useState<readonly PortfolioItemSearchResult[]>([]);

  const [search, searchResult] = useLazySearchQuery();

  const containerRef = useRef<HTMLDivElement>(null);
  const popperOptions = useMemo(
    (): any =>
      defaultOptions(document.body, {
        withSameWidth: true,
        offset: [0, 0],
        preventOverflow: true,
      }),
    [],
  );
  const { styles, attributes, update } = usePopper(anchor, popup, popperOptions);

  useEffect((): void => {
    if (keyword === '') {
      setItems([]);
    } else {
      search(keyword)
        .then((result: any /* What should the type be here? */): void => {
          if (result.isSuccess) {
            setItems(result.data);
          }
        })
        .catch(console.warn);
    }
  }, [keyword, search]);

  useEffect((): void => {
    update?.();
  }, [update]);

  useEffect((): void | VoidFunction => {
    if (currentValue === '') {
      setKeyword('');
      return;
    }

    const timeout = setTimeout((): void => {
      setKeyword(currentValue);
    }, 220);

    return (): void => {
      clearTimeout(timeout);
    };
  }, [currentValue]);

  const handleItemClicked = useCallback(
    (item: PortfolioItemSearchResult): void => {
      setKeyword('');
      setCurrentValue('');

      onSelected?.(item);
    },
    [onSelected],
  );

  return (
    <div ref={containerRef} className="relative z-1">
      <SearchBox
        ref={setAnchor}
        value={currentValue}
        placeholder="Search publicly traded companies"
        fullWidth={true}
        searching={searchResult.isFetching}
        onChange={setCurrentValue}
      />
      {ReactDOM.createPortal(
        <SearchResultsDropdown
          ref={setPopup}
          items={items}
          style={styles.popper}
          properties={attributes.popper}
          onItemClicked={handleItemClicked}
        />,
        document.body,
      )}
    </div>
  );
};
