import { SelectItem } from 'components/select';

export const enumToSelectItems = <E extends string | number | symbol>(
  enumerator: Record<string, E>,
  labels: Record<E, string>,
  filter: (item: E) => boolean = (_: E): boolean => false,
): Array<SelectItem<E>> =>
  Object.values(enumerator)
    .filter((item: E): boolean => item !== 'invalid')
    .map(
      (item: E): SelectItem<E> => ({
        value: item,
        label: labels[item],
        disabled: filter(item),
      }),
    );
