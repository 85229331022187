import { AnyTabProps, BaseTabProps } from 'components/tabs/components/baseTabProps';
import { TabButton } from 'components/tabs/components/tabButton';
import { ShrinkFactor, TabsVariant } from 'components/tabs/index';
import { ComingSoon } from 'components/tooltip/comingSoon';
import React, { useMemo } from 'react';
import { createClassName, percent } from 'utils/css/tools';

interface Props {
  readonly items: Array<React.ReactElement<AnyTabProps>>;
  readonly buttonVariant: TabsVariant;
  readonly shrinkFactor: ShrinkFactor;
  readonly alignment: 'center' | 'left';
}

export const TabBar: React.FC<Props> = ({
  items,
  buttonVariant,
  shrinkFactor,
  alignment,
}: Props): React.ReactElement => {
  const containerClassName = useMemo(
    (): string => containerClasses[buttonVariant],
    [buttonVariant],
  );

  const alignmentClassName = useMemo((): string => alignmentClasses[alignment], [alignment]);

  const widthClassName = useMemo(
    (): string =>
      createClassName({
        // FIXME: figure out a cleaner way to do this
        width:
          shrinkFactor > 0 && window.innerWidth > 1024
            ? percent(items.length, shrinkFactor)
            : undefined,
      }),
    [items.length, shrinkFactor],
  );

  const className = useMemo(
    (): string => [containerClassName, widthClassName, alignmentClassName].join(' '),
    [alignmentClassName, containerClassName, widthClassName],
  );

  return (
    <nav>
      <ul className={className}>
        {items.map((node: React.ReactElement<AnyTabProps>): React.ReactElement | null => {
          const { props } = node;

          if (BaseTabProps.isTab(props)) {
            return (
              <li key={node.key} className="flex-1 min-w-0">
                <Wrapper comingSoon={props.comingSoon}>
                  <TabButton
                    label={props.label}
                    path={props.path}
                    disabled={props.disabled}
                    variant={buttonVariant}
                  />
                </Wrapper>
              </li>
            );
          } else if (BaseTabProps.isLinkTab(props)) {
            return (
              <li key={node.key} className="flex-1 min-w-0">
                <Wrapper comingSoon={props.comingSoon}>
                  <TabButton
                    label={props.label}
                    path={props.to}
                    disabled={props.disabled}
                    variant={buttonVariant}
                  />
                </Wrapper>
              </li>
            );
          } else {
            return null;
          }
        })}
      </ul>
    </nav>
  );
};

const Wrapper: React.FC<React.PropsWithChildren<{ readonly comingSoon?: boolean }>> = ({
  children,
  comingSoon = false,
}: React.PropsWithChildren<{ readonly comingSoon?: boolean }>): React.ReactElement => {
  if (comingSoon) {
    return (
      <ComingSoon>
        <div>{children}</div>
      </ComingSoon>
    );
  } else {
    return <>{children}</>;
  }
};

const containerClasses: { [key in TabsVariant]: string } = {
  buttons: 'flex items-center text-sm uppercase border border-blue',
  tabs: 'flex items-center text-sm uppercase',
};

const alignmentClasses: { readonly left: string; readonly center: string } = {
  center: 'mx-auto',
  left: '',
};
