import styles from 'components/forms/styles.module.scss';
import React, { useCallback, useMemo, useState } from 'react';

interface OwnProps {
  readonly id?: string;
  readonly name: string;
  readonly label: string;
  readonly value: string;
  readonly alignment?: 'center' | 'left' | 'right';

  readonly className?: string;
  readonly rightDecorator?: React.ReactNode;
  readonly error?: string;
  readonly maxLength?: number;
  readonly placeholder?: string;
  readonly color?: 'default' | 'success' | 'error';
  readonly readOnly?: boolean;

  onChange?(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  onReturn?(): void;
  onTab?(): void;

  onFocus?(event: React.FocusEvent<HTMLInputElement>): void;
  onBlur?(): void;
}

type Props = React.PropsWithChildren<
  React.PropsWithoutRef<OwnProps> & React.RefAttributes<HTMLElement>
>;

export const InputBase: React.ForwardRefExoticComponent<Props> = React.forwardRef(function Input(
  {
    id,
    name,
    label,
    value = '',
    className,
    rightDecorator,
    color,
    alignment = 'left',
    children,
    placeholder,
    error,
    readOnly = false,

    onTab,
    onReturn,
    onChange,
    onFocus,
    onBlur,
  }: Props,
  ref: React.Ref<HTMLElement>,
): React.ReactElement {
  const [focus, setFocus] = useState<boolean>(false);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      onChange?.(event);
    },
    [onChange],
  );

  const handleBlur = useCallback((): void => {
    onBlur?.();
    setFocus(false);
  }, [onBlur]);

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>): void => {
      if (readOnly) {
        event.preventDefault();
        event.stopPropagation();

        return;
      }
      onFocus?.(event);
      setFocus(true);
    },
    [onFocus, readOnly],
  );

  const labelClassName = useMemo((): string => {
    const trimmedValue = value?.trim() ?? '';
    const classes = [styles.label];
    if (focus) {
      classes.push(styles.focused);
      switch (color) {
        case 'success':
          classes.push(styles.green);
          break;
        case 'error':
          classes.push(styles.red);
          break;
        default:
          classes.push(styles.blue);
          break;
      }
    } else {
      classes.push(styles.nonFocused);
      if (color === 'error') {
        classes.push(styles.red);
      }
    }

    if (trimmedValue.length > 0) {
      classes.push(styles.nonEmpty);
    } else {
      classes.push(styles.empty);
    }

    return classes.join(' ');
  }, [color, focus, value]);

  const containerClassName = useMemo((): string => {
    return [className, 'relative'].join(' ');
  }, [className]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      const { key } = event;

      switch (key) {
        case 'Return':
        case 'Enter':
          event.preventDefault();
          onReturn?.();
          break;
        case 'Tab':
          if (onTab) {
            event.preventDefault();
            onTab();
          }
          break;
      }
    },
    [onReturn, onTab],
  );

  if (!React.isValidElement<HTMLInputElement | HTMLButtonElement>(children)) {
    throw new Error('only one child allowed here');
  }

  // `name` and `value` have to be ignored here
  const { className: propsClassName, name: _, value: __, tabIndex, ...inputProps } = children.props;

  const inputClassName = React.useMemo((): string => {
    const classes = [baseInputClassName, `text-${alignment}`, propsClassName];
    if (color === 'error') {
      classes.push('text-red');
    }

    return classes.join(' ');
  }, [alignment, color, propsClassName]);

  const inputContainerClassName = useMemo((): string => {
    const classes = ['flex', 'items-center', 'w-full', 'border-b-2'];
    switch (color) {
      case 'success':
        classes.push('border-green');
        break;
      case 'error':
        classes.push('border-red');
        break;
      default:
        if (focus) {
          classes.push('border-blue');
        } else {
          classes.push('border-gray-300');
        }
        break;
    }

    return classes.join(' ');
  }, [color, focus]);

  const currentPlaceholder = useMemo((): string | undefined => {
    if (focus) {
      return placeholder;
    }

    return undefined;
  }, [focus, placeholder]);

  return (
    <div className={containerClassName}>
      <div className="h-6" />
      <label className={labelClassName} htmlFor={id}>
        {label}
      </label>
      <div className={inputContainerClassName}>
        {React.cloneElement<any>(children, {
          ref: ref,
          className: children.props.className ?? inputClassName,
          name: name,
          placeholder: currentPlaceholder,
          value: value,
          tabIndex: tabIndex ? tabIndex : readOnly ? -1 : undefined,
          readOnly: readOnly,
          onKeyDownCapture: handleKeyPress,
          onFocus: handleFocus,
          onBlur: handleBlur,
          onChange: handleChange,
          // If you insist ...
          ...inputProps,
        })}
        <div className="text-center leading-12">{rightDecorator}</div>
      </div>
      <div className="h-6 -mb-6 pointer-events-none">
        <p className="font-poppinsMedium text-xs text-red leading-6">{error}</p>
      </div>
    </div>
  );
});

const baseInputClassName =
  'flex-1 leading-10 py-1 px-1 font-poppins text-base bg-transparent outline-none opaque';
