import { Breadcrumbs } from 'components/breadcrumbs';
import { MainOutletContent } from 'components/mainOutletContent';
import { Tabs, TabsVariant } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import useOldTour from 'hooks/useOldTour';
import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';
import { Outlet, Route } from 'react-router-dom';
import { TextLine } from 'views/DataAnalysis/common/textLine';
import KeyFactors from 'views/Portfolios/KeyFactors';
import PortfoliosMaps from 'views/Portfolios/Maps';
import Notifications from 'views/Portfolios/Notifications';
import Overview from 'views/Portfolios/Overview';
import { Sensitivities } from 'views/Portfolios/Sensitivities/routes';
import Timeline from 'views/Portfolios/Timeline';
// Components
import tourConfig from 'views/Portfolios/tourConfig';

const Portfolios: React.FC = (): React.ReactElement => {
  const [_, tour] = useOldTour(tourConfig);

  useEffect(() => {
    mixpanel.track('PageView Portfolios List');
  }, []);

  return (
    <MainOutletContent>
      {tour}
      <div className="mb-2">
        <Breadcrumbs path={['PORTFOLIOS']} />
        <TextLine content="A summary of all your portfolios" />
      </div>

      <Tabs variant={TabsVariant.buttons}>
        <Tab label="Overview" element={<Overview />} />
        <Tab label="Geographies & Maps" element={<PortfoliosMaps />} path="maps" />
        <Tab
          label="Sensitivities & Correlations"
          element={<Sensitivities />}
          path="sensitivity/*"
        />
        <Tab label="Risk & Growth Factors" element={<KeyFactors />} path="risk-grow-factors/*" />
        <Tab label="News & Notifications" element={<Notifications />} path="notifications/*" />
        <Tab label="Timeline" element={<Timeline />} path="timeline" />
        <Route element={<Timeline />} path="timeline/:eventId" />
      </Tabs>

      <Outlet />
    </MainOutletContent>
  );
};

export default Portfolios;
