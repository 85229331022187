import React from 'react';
import { PortfolioItemType, PortfolioTableItem } from 'types/portfolioItem';
import { RelationshipType } from 'views/Portfolios/Details/components/AssetsTable/relationshipType';
import { TypeOfCash } from 'views/Portfolios/Details/components/AssetsTable/typeOfCash';

export interface Props {
  readonly item: PortfolioTableItem;
}

export const PurposeColumn: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  switch (item.type) {
    case PortfolioItemType.cash:
      return <TypeOfCash typeOfCash={item.data.typeOfCash} />;
    case PortfolioItemType.publicCompany:
      return <RelationshipType relationshipType={item.data.relationshipType} />;
    default:
      return <></>;
  }
};
