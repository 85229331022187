import { QueryStatus } from '@reduxjs/toolkit/query';
import { ConditionalRender } from 'components/conditionalRenderer';
import { FullScreenLoadingSpinner } from 'components/fullScreenLoadingSpinner';
import { Button } from 'components/genericButton';
import { Modal } from 'components/modal';
import { Select, SelectItem } from 'components/select';
import { isBefore, parse } from 'date-fns';
import { useGtag } from 'hooks/useGtag';
import { useQueryParameters } from 'hooks/useQueryParameters';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPortfolioItemItemSelector,
  reset,
  setItemType,
} from 'redux/reducers/addPortfolioItemFormReducer';
import { Page } from 'types/page';
import {
  isPortfolioArtItem,
  isPortfolioCashItem,
  isPortfolioCompanyItem,
  PortfolioCompanyItem,
  PortfolioItemType,
  portfolioItemTypeLabels,
  PortfolioTableItem,
} from 'types/portfolioItem';
import { enumToSelectItems } from 'utils/enumToSelectItems';
import { isEmptyOrNullOrUndefined } from 'utils/isEmptyOrNullOrUndefined';
import api, {
  useAddItemMutation,
  useUpdateItemMutation,
} from 'views/Portfolios/PortfoliosModal/api';
import { ArtItemForm } from 'views/Portfolios/PortfoliosModal/artItemForm';
import { CashItemForm } from 'views/Portfolios/PortfoliosModal/cashItemForm';
import { PublicCompanyItemForm } from 'views/Portfolios/PortfoliosModal/publicCompanyItemForm';

export interface Props {
  readonly name: string;
  readonly portfolioId: string;
  readonly relationshipId?: string;
  readonly editItem?: PortfolioCompanyItem;

  onClose(): void;
}

const AddItemForm: React.FC<Props> = ({
  name,
  editItem,
  portfolioId,
  onClose,
}: Props): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const item = useSelector(addPortfolioItemItemSelector);
  const queryParameters = useQueryParameters();

  const [addItem, addItemMutation] = useAddItemMutation();
  const [_, updateItemMutation] = useUpdateItemMutation();
  const [closeAfterSave, setCloseAfterSave] = useState<boolean>(false);

  const { trackEvent } = useGtag();
  const mutation = React.useMemo((): any => {
    if (editItem) {
      return updateItemMutation;
    } else {
      return addItemMutation;
    }
  }, [addItemMutation, editItem, updateItemMutation]);

  useEffect((): VoidFunction => {
    return (): void => {
      dispatch(reset());
    };
  }, [dispatch]);

  const handleSave = useCallback((): void => {
    addItem({
      portfolioId: portfolioId,
      item: item,
    });

    trackEvent('end-add-company-portfolio', {
      item: item,
    });

    return;
  }, [addItem, item, portfolioId, trackEvent]);

  const handleSaveAndClose = useCallback((): void => {
    setCloseAfterSave(true);
    handleSave();
  }, [handleSave]);

  useEffect((): void => {
    const { util } = api;
    if (!util) {
      console.warn('the `util` object is not defined on the api object');
      return;
    }

    if (mutation.status === QueryStatus.fulfilled) {
      dispatch(reset());

      dispatch(
        util.updateQueryData(
          'items',
          { ...queryParameters, id: portfolioId },
          (page: Page<PortfolioTableItem>): Page<PortfolioTableItem> => {
            const newItem = mutation.data;
            const isSame = (item: PortfolioTableItem): boolean => {
              return item.id === newItem.id;
            };
            const sortFn = (a: PortfolioTableItem, b: PortfolioTableItem): number => {
              const { added_at: aAddedAt } = a;
              const { added_at: bAddedAt } = b;

              const aAddedAtDate = parse(aAddedAt, 'yyyy-MM-dd', new Date());
              const bAddedAtDate = parse(bAddedAt, 'yyyy-MM-dd', new Date());

              return isBefore(aAddedAtDate, bAddedAtDate) ? 1 : -1;
            };
            // Need to map the data to the PortfolioItem type
            return Page.addOrReplace<PortfolioTableItem>(page, newItem, isSame, sortFn);
          },
        ),
      );
    }
  }, [dispatch, editItem, mutation.data, mutation.status, portfolioId, queryParameters]);

  useEffect((): void => {
    if (!closeAfterSave) {
      return;
    }

    if (mutation.status === QueryStatus.fulfilled) {
      onClose();
    }
  }, [closeAfterSave, mutation.status, onClose]);

  const handleItemTypeChange = useCallback(
    (value: PortfolioItemType): void => {
      dispatch(setItemType(value));
    },
    [dispatch],
  );

  return (
    <Modal.Content title={`Add items to ${name}`}>
      <div className="flex flex-col relative w-modal-sm min-h-3/4 gap-2">
        <ConditionalRender renderIf={isEmptyOrNullOrUndefined(item.id)}>
          <Select
            items={portfolioItemTypes}
            // FIXME: this has to be a mistake
            value={item.type}
            label="Item Type"
            onChange={handleItemTypeChange}
          />
        </ConditionalRender>

        <form autoComplete="off">
          <div className="flex flex-col gap-4 w-full flex-1">
            {/* For each item type we pick a different form */}
            {isPortfolioArtItem(item) && <ArtItemForm item={item} />}
            {isPortfolioCompanyItem(item) && <PublicCompanyItemForm item={item} />}
            {isPortfolioCashItem(item) && <CashItemForm item={item} />}
          </div>
        </form>

        <div className="flex items-center justify-end gap-3 pt-12 pb-6 -mb-6 bg-gray-light sticky bottom-0">
          {!isEmptyOrNullOrUndefined(item.id) ? (
            <>
              <Button
                type="button"
                variant="primary"
                label="Save"
                disabled={false}
                onClick={noop}
              />
            </>
          ) : (
            <>
              <Button
                type="button"
                variant="secondary"
                label="Save & Add"
                disabled={false}
                onClick={handleSave}
              />
              <Button
                type="button"
                variant="primary"
                label="Save & Close"
                disabled={false}
                onClick={handleSaveAndClose}
              />
            </>
          )}
        </div>
        <FullScreenLoadingSpinner visible={mutation.status === QueryStatus.pending} />
      </div>
    </Modal.Content>
  );
};

export default AddItemForm;

const portfolioItemTypes: Array<SelectItem<PortfolioItemType>> = enumToSelectItems(
  PortfolioItemType,
  portfolioItemTypeLabels,
  (value: PortfolioItemType): boolean =>
    value !== PortfolioItemType.publicCompany &&
    value !== PortfolioItemType.cash &&
    value !== PortfolioItemType.art,
);
