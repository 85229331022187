import { InputBase } from 'components/inputBase';
import { defaultLocale } from 'constants/defaultLocale';
import React from 'react';

interface Props {
  readonly id?: string;
  readonly name: string;
  readonly value: number | null | undefined;
  readonly label: string;
  readonly precision?: number;

  readonly type?: 'number' | 'percent';
  readonly readOnly?: boolean;

  onValueChange(value: number | null): void;
}

export const NumericInput: React.FC<Props> = ({
  id,
  name,
  value,
  precision = 2,
  readOnly = false,
  type = 'number',
  label,
  onValueChange,
}: Props): React.ReactElement => {
  const [currentValue, setCurrentValue] = React.useState<string>('');
  const [input, setInput] = React.useState<HTMLElement | null>(null);

  const formatter = React.useMemo((): Intl.NumberFormat => {
    return new Intl.NumberFormat(defaultLocale, {
      maximumFractionDigits: precision,
      minimumFractionDigits: precision,
    });
  }, [precision]);

  const DecimalSeparator = React.useMemo((): string => {
    const parts = formatter.formatToParts(1);
    const found = parts.find((part: Intl.NumberFormatPart): boolean => part.type === 'decimal');
    if (found) {
      return found.value;
    } else {
      return '.';
    }
  }, [formatter]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      const input = event.target;
      if (input instanceof HTMLInputElement) {
        const { value } = input;

        const currentPosition = input.selectionStart ?? 0;
        if (event.code === 'NumpadDecimal' || event.key === DecimalSeparator) {
          event.preventDefault();

          if (value[currentPosition] === DecimalSeparator) {
            input.setSelectionRange(currentPosition + 1, currentPosition + 1);
          }

          return;
        }

        switch (event.key) {
          case '0':
          case '1':
          case '2':
          case '3':
          case '4':
          case '5':
          case '6':
          case '7':
          case '8':
          case '9':
          case 'Tab':
          case 'Delete':
          case 'ArrowLeft':
          case 'ArrowRight':
          case 'End':
          case 'Home':
            return;
          case 'Backspace':
            if (value[currentPosition - 1] === DecimalSeparator) {
              input.setSelectionRange(currentPosition - 1, currentPosition - 1);
            }
            return;
          default:
            event.preventDefault();
        }
      }
    },
    [DecimalSeparator],
  );

  const handleValueChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { target: input } = event;
      const { value } = input;

      if (value === '' || value.startsWith(DecimalSeparator)) {
        input.value = '';
        setCurrentValue('');
        onValueChange(null);
        return;
      }

      const decimalSeparatorRegex = new RegExp(`[^0-9${DecimalSeparator}]`, 'g');
      const cleanValue = value //
        .replace(decimalSeparatorRegex, '') // Thousands separators
        .replace(DecimalSeparator, '.'); // Normalize decimal separator

      const numeric = Number(cleanValue);
      if (isNaN(numeric)) {
        return;
      }
      const formattedValue = formatter.format(numeric);
      const position = input.selectionStart ?? 0;
      const delta = countNonDigits(value, position) - countNonDigits(formattedValue, position);

      input.value = formattedValue;
      input.setSelectionRange(position - delta, position - delta);

      setCurrentValue(formattedValue);
      onValueChange(numeric);
    },
    [DecimalSeparator, formatter, onValueChange],
  );

  React.useEffect((): void => {
    if (value !== null && typeof value === 'number') {
      setCurrentValue(formatter.format(value));
    } else {
      setCurrentValue('');
    }
  }, [formatter, input, value]);

  const handleFocus = React.useCallback((event: React.FocusEvent<HTMLInputElement>): void => {
    const input = event.target;
    input.select();
  }, []);

  const rightDecorator = React.useMemo(
    (): React.ReactElement =>
      type === 'percent' ? <span className="text-gray-400">%</span> : <></>,
    [type],
  );

  return (
    <div className="flex items-center gap-2 w-full">
      <InputBase
        ref={setInput}
        name={name}
        label={label}
        className="flex-1"
        rightDecorator={rightDecorator}
        alignment="right"
        value={currentValue}
        readOnly={readOnly}
        onFocus={handleFocus}
        onChange={handleValueChange}
      >
        <input id={id} onKeyDown={handleKeyDown} />
      </InputBase>
    </div>
  );
};

const countNonDigits = (value: string, limit: number): number =>
  (value.slice(0, limit).match(/[^0-9]/g) ?? []).length;
