import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Page } from 'types/page';
import {
  AddPortfolioItemPayload,
  PortfolioCompanyRelationshipType,
  PortfolioItem,
  PortfolioItemType,
  PortfolioTableItem,
} from 'types/portfolioItem';
import { baseURL, defaultAPIHeaders } from 'utils/config/axiosConfig';
import { toQueryString } from 'utils/toQueryString';
import { ProductType } from 'views/Portfolios/PortfoliosModal/utils';

export interface PortfolioItemSearchResultBase {
  readonly type: PortfolioItemType;
  readonly id: string;
  readonly name: string;
}

export interface PublicCompanyData {
  readonly ticker: string;
  readonly currency: string;
  readonly exchange: string;
  readonly price: number;
}

export interface PortfolioItemPublicCompanySearchResult extends PortfolioItemSearchResultBase {
  readonly type: PortfolioItemType.publicCompany;
  readonly data: PublicCompanyData;
}

export type PortfolioItemSearchResult = PortfolioItemPublicCompanySearchResult;

export interface PortfolioItemPayload {
  readonly organization_id: string;
  readonly product: ProductType;
  readonly relationship: PortfolioCompanyRelationshipType;
  readonly currency_id: string | null;
  readonly size_of_relationship: number;
}

export interface PortfolioDeleteItemPayload {
  readonly portfolioId: string;
  readonly itemId: string;
}

export interface ItemsQueryParams {
  readonly id: string;
  readonly order_by?: string;
}

export interface UpdatePortfolioItemPayload {
  readonly portfolioId: string;
  readonly itemId: string;
  readonly body: Omit<PortfolioItemPayload, 'organization_id'>;
}

const api = createApi({
  reducerPath: 'portfoliosApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseURL}/api/v1/portfolios/`,
    prepareHeaders: defaultAPIHeaders,
  }),
  tagTypes: ['Items'],
  endpoints: builder => ({
    search: builder.query<readonly PortfolioItemSearchResult[], string>({
      query: (keyword: string): string => `/items/?keyword=${keyword}`,
    }),
    items: builder.query<Page<PortfolioTableItem>, ItemsQueryParams>({
      query: ({ id, ...query }: ItemsQueryParams): string => `/${id}/items/${toQueryString(query)}`,
      serializeQueryArgs: ({ queryArgs }): ItemsQueryParams => ({
        id: queryArgs.id,
      }),
      providesTags: ['Items'],
    }),
    removeItem: builder.mutation<void, PortfolioDeleteItemPayload>({
      query: ({ portfolioId, itemId }: PortfolioDeleteItemPayload) => ({
        url: `/${portfolioId}/items/${itemId}`,
        method: 'DELETE',
      }),
      onQueryStarted: (
        { portfolioId, itemId }: PortfolioDeleteItemPayload,
        { dispatch }: any /* I hate redux toolkit */,
      ) => {
        dispatch(
          api.util.updateQueryData(
            'items',
            { id: portfolioId },
            (draft: Page<PortfolioTableItem>): Page<PortfolioTableItem> => {
              return Page.filter(draft, (item: PortfolioTableItem) => item.id !== itemId);
            },
          ),
        );
      },
      // FIXME: Should consider handling the onQueryError
      invalidatesTags: ['Items'],
    }),
    addItem: builder.mutation<readonly PortfolioItem[], AddPortfolioItemPayload>({
      query: (payload: AddPortfolioItemPayload) => ({
        url: `/${payload.portfolioId}/items`,
        method: 'POST',
        body: payload.item,
      }),
    }),
    updateItem: builder.mutation<void, UpdatePortfolioItemPayload>({
      query: ({ portfolioId, itemId, body }: UpdatePortfolioItemPayload) => ({
        url: `/${portfolioId}/items/${itemId}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['Items'],
    }),
  }),
});

export const isPortfolioCompanyItemSearchResult = (
  item: PortfolioItemSearchResultBase,
): item is PortfolioItemPublicCompanySearchResult => {
  return item.type === PortfolioItemType.publicCompany;
};

export default api;

export const {
  useLazySearchQuery,
  useAddItemMutation,
  useItemsQuery,
  useRemoveItemMutation,
  useUpdateItemMutation,
} = api;
