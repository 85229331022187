import { ConditionalRender } from 'components/conditionalRenderer';
import SVGIcon from 'components/icons/SVGIcon';
import { Select, SelectItem } from 'components/select';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setRelationshipType, setSearchResult } from 'redux/reducers/addPortfolioItemFormReducer';
import {
  PortfolioCompanyItem,
  PortfolioCompanyRelationshipType,
  portfolioCompanyRelationshipTypeLabels,
} from 'types/portfolioItem';
import { enumToSelectItems } from 'utils/enumToSelectItems';
import { isEmptyOrNullOrUndefined } from 'utils/isEmptyOrNullOrUndefined';
import { PortfolioItemSearchResult } from 'views/Portfolios/PortfoliosModal/api';
import { AssetSearchInput } from 'views/Portfolios/PortfoliosModal/assetSearchInput';
import { CompanyFormSelector } from 'views/Portfolios/PortfoliosModal/companyFormSelector';

interface Props {
  readonly item: PortfolioCompanyItem;
}

export const PublicCompanyItemForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const dispatch = useDispatch();

  const clearSearchResult = useCallback((): void => {
    dispatch(setSearchResult(null));
  }, [dispatch]);

  const handleSearchResult = useCallback(
    (result: PortfolioItemSearchResult): void => {
      dispatch(setSearchResult(result));
    },
    [dispatch],
  );

  const handleRelationshipTypeChange = useCallback(
    (value: PortfolioCompanyRelationshipType): void => {
      dispatch(setRelationshipType(value));
    },
    [dispatch],
  );

  return (
    <>
      <div>
        <label className="text-gray leading-4 text-xs font-poppinsMedium">Company Name</label>
        {!isEmptyOrNullOrUndefined(item.id) ? (
          <div>
            <div className="leading-10 py-1 h-12">
              <p className="block">{item.name}</p>
            </div>
            <div className="w-full h-px -mt-px bg-gray-300" />
          </div>
        ) : !isEmptyOrNullOrUndefined(item.companyId) ? (
          <div className="flex items-center justify-between h-12 px-2 border-b-2 border-gray-300">
            <p className="block">{item.name}</p>
            <SVGIcon
              name="close-popup-icon"
              className="fill-current w-3 h-3 cursor-pointer hover:text-red-500 text-gray"
              onClick={clearSearchResult}
            />
          </div>
        ) : (
          <AssetSearchInput onSelected={handleSearchResult} />
        )}
      </div>

      <ConditionalRender renderIf={!isEmptyOrNullOrUndefined(item.companyId)}>
        <Select
          id="relationshipType"
          label="Company Relationship to You"
          items={relationshipTypes}
          value={item.relationshipType}
          onChange={handleRelationshipTypeChange}
        />

        <CompanyFormSelector item={item} />
      </ConditionalRender>
    </>
  );
};

const relationshipTypes: ReadonlyArray<SelectItem<PortfolioCompanyRelationshipType>> =
  enumToSelectItems(PortfolioCompanyRelationshipType, portfolioCompanyRelationshipTypeLabels);
