import SVGIcon from 'components/icons/SVGIcon';
import styles from 'components/searchBox/styles.module.scss';
import React, { useCallback, useEffect, useState } from 'react';

interface Props {
  readonly value: string;
  readonly searching?: boolean;
  readonly placeholder?: string;
  readonly fullWidth?: boolean;

  onChange(keyword: string): void;
}

export const SearchBox: React.FC<React.PropsWithoutRef<Props> & React.RefAttributes<any>> =
  React.forwardRef(function SearchBox(
    { value, searching = false, placeholder = 'Search', fullWidth = false, onChange }: Props,
    ref: React.Ref<HTMLDivElement>,
  ): React.ReactElement {
    const [searchKeyword, setSearchKeyword] = useState<string>('');
    const [focused, setFocused] = React.useState<boolean>(false);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const handleSearchKeywordChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setSearchKeyword(value);
      },
      [],
    );

    const clearSearchKeyword = useCallback((): void => {
      onChange('');

      setTimeout((): void => {
        inputRef.current?.focus();
      }, 0);
    }, [onChange]);

    useEffect((): void => {
      setSearchKeyword(value);
    }, [value]);

    useEffect((): VoidFunction => {
      const timeout = setTimeout((): void => {
        onChange(searchKeyword);
      }, 500);

      return (): void => {
        clearTimeout(timeout);
      };
    }, [onChange, searchKeyword]);

    const derivedContainerClassName = React.useMemo((): string => {
      return [
        containerClassName,
        fullWidth ? 'w-full' : 'w-80',
        focused ? 'border-blue' : undefined,
      ].join(' ');
    }, [focused, fullWidth]);

    const handleFocus = React.useCallback((): void => {
      setFocused(true);
    }, []);

    const handleBlur = React.useCallback((): void => {
      setFocused(false);
    }, []);

    return (
      <div ref={ref} className={derivedContainerClassName}>
        <SVGIcon name="dark-search-icon" className={iconClassName} />
        <input
          ref={inputRef}
          className={inputClassName}
          placeholder={placeholder}
          value={searchKeyword}
          readOnly={searching}
          onChange={handleSearchKeywordChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />

        <div className={rightDecorationClassName}>
          {searching ? <div className={styles.searchSpinner} /> : null}

          {!searching && searchKeyword !== null && searchKeyword.trim() !== '' ? (
            <SVGIcon
              name="close-popup-icon"
              onClick={clearSearchKeyword}
              className="fill-current w-3 h-3 cursor-pointer hover:text-red-500 text-gray"
            />
          ) : null}
        </div>
      </div>
    );
  });

const containerClassName = 'flex items-center border-b-2 border-gray-medium h-12 px-3';
const inputClassName =
  'leading-12 h-12 flex-1 w-full font-poppins px-4 text-sm outline-none focus:border-blue bg-transparent';
const iconClassName = 'w-4 h-4';
const rightDecorationClassName = 'relative w-4 h-4 flex items-center justify-center';
