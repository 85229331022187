import React from 'react';
import {
  isPortfolioCompanyClientOrCustomerItem,
  isPortfolioCompanyEmployerItem,
  isPortfolioCompanyInvestmentItem,
  isPortfolioCompanyOtherItem,
  isPortfolioCompanyPartnerItem,
  isPortfolioCompanySupplierItem,
  PortfolioCompanyItem,
} from 'types/portfolioItem';
import { ClientOrCustomerItemForm } from 'views/Portfolios/PortfoliosModal/clientOrCustomerItemForm';
import { EmployerItemForm } from 'views/Portfolios/PortfoliosModal/employerItemForm';
import { InvestmentItemForm } from 'views/Portfolios/PortfoliosModal/investmentItemForm';
import { OtherInvestmentItemForm } from 'views/Portfolios/PortfoliosModal/otherInvestmentItemForm';
import { PartnerItemForm } from 'views/Portfolios/PortfoliosModal/partnerItemForm';
import { SupplierItemForm } from 'views/Portfolios/PortfoliosModal/supplierItemForm';

interface Props {
  readonly item: PortfolioCompanyItem;
}

export const CompanyFormSelector: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  if (isPortfolioCompanyInvestmentItem(item)) {
    return <InvestmentItemForm item={item} />;
  } else if (isPortfolioCompanyClientOrCustomerItem(item)) {
    return <ClientOrCustomerItemForm item={item} />;
  } else if (isPortfolioCompanyPartnerItem(item)) {
    return <PartnerItemForm item={item} />;
  } else if (isPortfolioCompanySupplierItem(item)) {
    return <SupplierItemForm item={item} />;
  } else if (isPortfolioCompanyEmployerItem(item)) {
    return <EmployerItemForm item={item} />;
  } else if (isPortfolioCompanyOtherItem(item)) {
    return <OtherInvestmentItemForm item={item} />;
  } else {
    throw new Error();
  }
};
