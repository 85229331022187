import { PleaseUpgradeTableItem } from 'components/pleaseUpgradeTableItem';
import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import React from 'react';
import { Link } from 'react-router-dom';
import { PortfolioItemType, PortfolioTableItem } from 'types/portfolioItem';
import { ActionButtons } from 'views/Portfolios/Details/components/AssetsTable/actionButtons';
import { PurposeColumn } from 'views/Portfolios/Details/components/AssetsTable/purposeColumn';

const columns: ReadonlyArray<ColumnDefinition<PortfolioTableItem>> = [
  {
    name: 'name',
    label: 'Item Name',
    type: ColumnType.custom,
    render: (
      _: PortfolioTableItem[keyof PortfolioTableItem] | null,
      item: PortfolioTableItem,
    ): React.ReactNode => {
      switch (item.type) {
        case PortfolioItemType.cash:
          if (!item.id) {
            return (
              <div key={item.id}>
                <p className={companyNameClassName}>{item.name}</p>
                <PleaseUpgradeTableItem anchorToNext="tr" />
              </div>
            );
          } else {
            return <div>{item.name}</div>;
          }
        case PortfolioItemType.publicCompany:
          if (!item.id) {
            return (
              <div key={item.id}>
                <p className={companyNameClassName}>{item.name}</p>
                <PleaseUpgradeTableItem anchorToNext="tr" />
              </div>
            );
          } else {
            const url = `/data-analysis/company/details/${item.data.companyId}`;

            return (
              <Link key={item.id} className={linkClassName} title={item.name} to={url}>
                {item.name}
              </Link>
            );
          }
      }
    },
    weight: 2,
    headerAlignment: Alignment.left,
  },
  {
    name: 'type',
    label: 'Purpose',
    type: ColumnType.custom,
    render: (_: string | null, item: PortfolioTableItem): React.ReactNode => {
      return <PurposeColumn item={item} />;
    },
    weight: 1,
  },
  {
    name: 'currency',
    label: 'Currency',
    type: ColumnType.text,
    weight: 1,
    alignment: Alignment.center,
  },
  {
    name: 'value',
    label: 'Value',
    type: ColumnType.numeric,
    precision: 2,
    weight: 1,
    alignment: Alignment.center,
  },
  {
    name: 'change',
    label: '52 week change',
    type: ColumnType.percent,
    precision: 2,
    weight: 1,
    alignment: Alignment.center,
  },
  {
    name: 'added_at',
    label: 'Added',
    type: ColumnType.date,
    alignment: Alignment.center,
    width: 120,
  },
  {
    name: 'id',
    label: '',
    type: ColumnType.custom,
    render: (value: string | null, item: PortfolioTableItem): React.ReactNode => {
      if (value === null) {
        return <></>;
      }

      return <ActionButtons item={item} />;
    },
    width: 173,
  },
];

export default columns;

const companyNameClassName =
  'font-poppins normal-case text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap text-gray';

const linkClassName = `${companyNameClassName} text-blue`;
